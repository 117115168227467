<script lang="ts" setup>
withDefaults(defineProps<{
  title?: string
}>(), {
  title: undefined
});
</script>

<template>
  <v-container>
    <v-row>
      <v-col>
        <h3 class="text-h3 text-center h-one-line mt-8">
          {{ title }}
        </h3>
      </v-col>
    </v-row>
  </v-container>
</template>
