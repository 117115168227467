import { createI18n } from 'vue-i18n';
import { messages } from '~/locales';
import { numberFormats } from '~/utils';

export const i18n = createI18n({
  legacy: false,
  locale: 'fr',
  fallbackLocale: 'fr',
  globalInjection: true,
  messages,
  numberFormats
});
