import type { NumberOptions } from 'vue-i18n';

const defaultNumberFormat: Record<string, NumberOptions> = {
  currency: {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0,
    currencyDisplay: 'narrowSymbol'
  },
  multiple: {
    style: 'decimal',
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
    minimumSignificantDigits: 2
  },
  percent: {
    style: 'percent',
    useGrouping: true
  }
};

export const numberFormats = {
  fr: { ...defaultNumberFormat }
};

