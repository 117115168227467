import { Subscription } from '@opalecapital/api-client';

export const STATUS_COLOR: Record<Subscription.Status, string> = {
  [Subscription.Status.OnGoing]: 'warning',
  [Subscription.Status.WaitingForValidation]: 'warning',
  [Subscription.Status.Processing]: 'warning',
  [Subscription.Status.Cancelled]: 'warning',
  [Subscription.Status.SentForSignature]: 'info',
  [Subscription.Status.InvestorSigned]: 'info',
  [Subscription.Status.Rejected]: 'disabled',
  [Subscription.Status.Sent]: 'info',
  [Subscription.Status.Signed]: 'info',
  [Subscription.Status.Validated]: 'success',
};
