<script lang="ts" setup>
import type { MenuItemList } from '~/composables';

withDefaults(defineProps<{
  actionItems?: MenuItemList,
}>(), {
  actionItems: () => []
});
</script>

<template>
  <v-navigation-drawer class="app-navigation-drawer">
    <v-list
      density="comfortable"
      nav
    >
      <template
        v-for="(actionItem, actionIndex) in actionItems"
        :key="`navDrawer.actionItem.${actionIndex}`"
      >
        <v-list-group v-if="'items' in actionItem">
          <template #activator="{ props }">
            <v-list-item
              v-bind="props"
              :title="actionItem.text"
            />
          </template>
          <v-list-item
            v-for="(subItem, subIndex) in actionItem.items"
            :key="`navDrawer.actionItem.${actionIndex}.subitem.${subIndex}`"
            :title="subItem.text"
            :to="subItem.to"
          />
        </v-list-group>
        <v-list-item
          v-else
          :title="actionItem.text"
          :to="actionItem.to"
        />
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<style lang="scss">
  .app-navigation-drawer {
    .v-list-item {
      &--link:hover {
        text-decoration: none;
      }

      &--nav {
        .v-list-item-title {
          line-height: 2rem;
        }
      }
    }
  }
</style>
