import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

type SnackbarSettings = {
  closable?: boolean;
  type: 'info' | 'success' | 'warning' | 'error';
  title: string;
  text?: string;
  btnPrimaryText?: string;
  btnSecondaryText?: string;
};

export const useSnackbar = defineStore(
  'snackbar',
  () => {
    const defaultSettings: SnackbarSettings = {
      closable: true,
      type: 'info',
      title: ''
    };

    const display = ref<boolean>(false);
    const localSettings = ref<SnackbarSettings>(defaultSettings);

    const hide = () => display.value = false;
    const show = (settings: SnackbarSettings) => {
      localSettings.value = { ...defaultSettings, ...settings };
      display.value = true;
    };

    return {
      display,
      settings: computed(() => localSettings.value),
      hide,
      show
    };
  }
);
