// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import 'vuetify/styles';
import { OHelperCard, OMarkdown } from '@opalecapital/ui/components';
import { createOpaleComponents } from '@opalecapital/ui-components';
import { defaults, icons, themes } from '@opalecapital/ui-themes';
import { createVuetify } from 'vuetify';
import * as vuetifyComponents from 'vuetify/components';
import * as directives from 'vuetify/directives';

const theme = {
  defaultTheme: 'opale-light',
  themes
};

const components = {
  ...vuetifyComponents, OMarkdown, OHelperCard
};

export const opaleComponents = createOpaleComponents();
export const vuetify = createVuetify({ components, defaults, directives, icons, theme });

