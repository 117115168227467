import '~/styles/main.scss';
import { MdEditor } from 'md-editor-v3';
import { createApp } from 'vue';
import {
  head,
  i18n,
  opaleComponents,
  persistedPinia,
  vuetify
} from '~/modules';
import {
  initializeSentry,
  opaleApi
} from '~/utils';
import App from './App.vue';
import { router } from './router/router';

const app = createApp(App);

app.use(head)
  .use(i18n)
  .use(opaleApi)
  .use(persistedPinia)
  .use(router)
  .use(opaleComponents)
  .use(vuetify);

app.component('MdEditor', MdEditor);

initializeSentry(app, router);

app.mount('#app');
