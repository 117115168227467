import { User } from '@opalecapital/api-client';
import type { MergeHead } from '@unhead/schema';
import type { VueHeadClient } from '@unhead/vue';
import { injectHead } from '@vueuse/head';
import { computed, isProxy, ref, toRaw } from 'vue';
import { useI18n } from 'vue-i18n';
import type { RouteLocationRaw } from 'vue-router';
import { useRoute } from 'vue-router';
import { useAuth  } from '~/stores';

export type NavItem = {
  text: string,
  to: { name: string },
};

export type WrapperItem = {
  text: string,
  items: Array<NavItem>
};

export type MenuItemList = Array<WrapperItem | NavItem>;

export type BreadcrumbsItem = {
  title: string;
  key?: string;
  to?: RouteLocationRaw | undefined;
};

const addItems = (computedItems: MenuItemList, newItems: Array<WrapperItem | NavItem>) => {
  newItems.forEach((newItem, newItemIndex) => {
    if ('items' in newItem) {
      addWrapperItem(computedItems, newItem, newItemIndex);
    }
    else {
      addNavItem(computedItems, newItem, newItemIndex);
    }
  });
};

const addNavItem = (computedItems: MenuItemList, newNavItem: NavItem, newItemIndex: number) => {
  if (!computedItems.find(item => item.text == newNavItem.text)) {
    computedItems.splice(newItemIndex, 0, newNavItem);
  }
};

const addWrapperItem = (computedItems: MenuItemList, newWrapperItem: WrapperItem, newItemIndex: number) => {
  const menuItem = computedItems.find(item => item.text == newWrapperItem.text);

  if (!menuItem) {
    computedItems.splice(newItemIndex, 0, newWrapperItem);
  }
  else {
    if ('items' in menuItem) {
      const newNavItems: Array<NavItem> = [...newWrapperItem.items];
      menuItem.items.forEach(item => {
        if (!newNavItems.find(newItem => newItem.text == item.text)) {
          newNavItems.push(item);
        }
      });
      menuItem.items = newNavItems;
    }
  }
};

type HeadEntries = ReturnType<VueHeadClient<MergeHead>['headEntries']>;

const titleFromEntries = (entries: HeadEntries): string | undefined => {
  let title: string | undefined = undefined;

  entries.forEach(entry => {
    const rawInput = isProxy(entry.input) ? toRaw(entry.input) : entry.input;

    if ('title' in rawInput && rawInput.title) {
      title = rawInput.title.toString();
    }
  });

  return title;
};

const useNavItems = () => {
  const { t } = useI18n();

  const getFundManagerItems = () => ([
    { text: t('_app.nav.actionItems.investorProfileList'), to: { name: 'investor-profile-list' } },
    { text: t('_app.nav.actionItems.subscriptionList'), to: { name: 'subscription-list' } },
  ]);

  const getSalesActionItems = () => ([
    { text: t('_app.nav.actionItems.investorProfileList'), to: { name: 'investor-profile-list' } },
    { text: t('_app.nav.actionItems.subscriptionList'), to: { name: 'subscription-list' } },
    {
      text : t('_app.nav.actionItems.partners.title'),
      items: [
        { text: t('_app.nav.actionItems.partners.companyList'), to: { name: 'partner-company-list' } },
        { text: t('_app.nav.actionItems.partners.profileList'), to: { name: 'partner-company-profile-list' } }
      ]
    },
    { text: t('_app.nav.actionItems.subscriptions.title'),
      items: [
        { text: t('_app.nav.actionItems.subscriptions.profileList'), to: { name: 'investor-profile-list' } },
        { text: t('_app.nav.actionItems.subscriptions.list'), to: { name: 'subscription-list' } }
      ]
    },
    {
      text: t('_app.nav.actionItems.funds.title'),
      items: [
        { text: t('_app.nav.actionItems.funds.list'),  to: { name: 'fund-list' } },
        { text: t('_app.nav.actionItems.funds.managementCompanyList'),  to: { name: 'fund-manager-company-list' } },
        { text: t('_app.nav.actionItems.funds.custodianBankList'),  to: { name: 'custodian-bank-list' } },
      ]
    },
    {
      text: t('_app.nav.actionItems.users.title'),
      items: [
        { text: t('_app.nav.actionItems.users.investorList'), to: { name: 'investor-list' } },
        { text: t('_app.nav.actionItems.users.partnerList'), to: { name: 'partner-list' } }
      ]
    },
  ]);

  const getMiddleOfficeActionItems = () => ([
    { text: t('_app.nav.actionItems.investorProfileList'), to: { name: 'investor-profile-list' } },
    { text: t('_app.nav.actionItems.subscriptionList'), to: { name: 'subscription-list' } },
    {
      text : t('_app.nav.actionItems.partners.title'),
      items: [
        { text: t('_app.nav.actionItems.partners.companyList'), to: { name: 'partner-company-list' } },
        { text: t('_app.nav.actionItems.partners.profileList'), to: { name: 'partner-company-profile-list' } }
      ]
    },
    { text: t('_app.nav.actionItems.subscriptions.title'),
      items: [
        { text: t('_app.nav.actionItems.subscriptions.profileList'), to: { name: 'investor-profile-list' } },
        { text: t('_app.nav.actionItems.subscriptions.list'), to: { name: 'subscription-list' } }
      ]
    },
    {
      text: t('_app.nav.actionItems.funds.title'),
      items: [
        { text: t('_app.nav.actionItems.funds.list'),  to: { name: 'fund-list' } },
        { text: t('_app.nav.actionItems.funds.managementCompanyList'),  to: { name: 'fund-manager-company-list' } },
        { text: t('_app.nav.actionItems.funds.custodianBankList'),  to: { name: 'custodian-bank-list' } },
      ]
    },
    {
      text: t('_app.nav.actionItems.users.title'),
      items: [
        { text: t('_app.nav.actionItems.users.investorList'), to: { name: 'investor-list' } },
        { text: t('_app.nav.actionItems.users.partnerList'), to: { name: 'partner-list' } }
      ]
    },
    { text: t('_app.nav.actionItems.config.title'),
      items: [
        { text: t('_app.nav.actionItems.config.profileTemplateList'), to: { name: 'profile-template-list' } }
      ]
    }
  ]);

  const getFundSelectionActionItems = () => ([
    { text: t('_app.nav.actionItems.investorProfileList'), to: { name: 'investor-profile-list' } },
    { text: t('_app.nav.actionItems.subscriptionList'), to: { name: 'subscription-list' } },
    {
      text : t('_app.nav.actionItems.partners.title'),
      items: [
        { text: t('_app.nav.actionItems.partners.companyList'), to: { name: 'partner-company-list' } },
        { text: t('_app.nav.actionItems.partners.profileList'), to: { name: 'partner-company-profile-list' } }
      ]
    },
    { text: t('_app.nav.actionItems.subscriptions.title'),
      items: [
        { text: t('_app.nav.actionItems.subscriptions.profileList'), to: { name: 'investor-profile-list' } },
        { text: t('_app.nav.actionItems.subscriptions.list'), to: { name: 'subscription-list' } }
      ]
    },
    {
      text: t('_app.nav.actionItems.funds.title'),
      items: [
        { text: t('_app.nav.actionItems.funds.list'),  to: { name: 'fund-list' } },
        { text: t('_app.nav.actionItems.funds.managementCompanyList'),  to: { name: 'fund-manager-company-list' } },
        { text: t('_app.nav.actionItems.funds.custodianBankList'),  to: { name: 'custodian-bank-list' } },
      ]
    },
    {
      text: t('_app.nav.actionItems.users.title'),
      items: [
        { text: t('_app.nav.actionItems.users.investorList'), to: { name: 'investor-list' } },
        { text: t('_app.nav.actionItems.users.partnerList'), to: { name: 'partner-list' } }
      ]
    }
  ]);

  const getAdminActionItems = () => ([
    { text: t('_app.nav.actionItems.investorProfileList'), to: { name: 'investor-profile-list' } },
    { text: t('_app.nav.actionItems.subscriptionList'), to: { name: 'subscription-list' } },
    {
      text : t('_app.nav.actionItems.partners.title'),
      items: [
        { text: t('_app.nav.actionItems.partners.companyList'), to: { name: 'partner-company-list' } },
        { text: t('_app.nav.actionItems.partners.profileList'), to: { name: 'partner-company-profile-list' } }
      ]
    },
    { text: t('_app.nav.actionItems.subscriptions.title'),
      items: [
        { text: t('_app.nav.actionItems.subscriptions.profileList'), to: { name: 'investor-profile-list' } },
        { text: t('_app.nav.actionItems.subscriptions.list'), to: { name: 'subscription-list' } }
      ]
    },
    {
      text: t('_app.nav.actionItems.funds.title'),
      items: [
        { text: t('_app.nav.actionItems.funds.list'),  to: { name: 'fund-list' } },
        { text: t('_app.nav.actionItems.funds.managementCompanyList'),  to: { name: 'fund-manager-company-list' } },
        { text: t('_app.nav.actionItems.funds.custodianBankList'),  to: { name: 'custodian-bank-list' } },
      ]
    },
    {
      text: t('_app.nav.actionItems.users.title'),
      items: [
        { text: t('_app.nav.actionItems.users.investorList'), to: { name: 'investor-list' } },
        { text: t('_app.nav.actionItems.users.partnerList'), to: { name: 'partner-list' } },
        { text: t('_app.nav.actionItems.users.fundManagerList'), to: { name: 'fund-manager-list' } },
        { text: t('_app.nav.actionItems.users.operatorList'), to: { name: 'operator-list' } }
      ]
    },
    { text: t('_app.nav.actionItems.config.title'),
      items: [
        { text: t('_app.nav.actionItems.config.profileTemplateList'), to: { name: 'profile-template-list' } }
      ]
    }
  ]);

  return {
    getFundManagerItems,
    getMiddleOfficeActionItems,
    getSalesActionItems,
    getFundSelectionActionItems,
    getAdminActionItems };
};

export const useNav = () => {
  const { t } = useI18n();
  const authStore = useAuth();
  const {
    getFundManagerItems,
    getMiddleOfficeActionItems,
    getSalesActionItems,
    getFundSelectionActionItems,
    getAdminActionItems
  } = useNavItems();
  const route = useRoute();
  const head = injectHead();

  const actionItems = computed(() => {
    if (!authStore.authenticated) {
      return [];
    }

    const computedItems: MenuItemList = [];

    if (authStore.hasRole(User.Role.FundManager)) {
      addItems(computedItems, getFundManagerItems());
    }

    if (authStore.hasRole(User.Role.OperatorMiddleOffice)) {
      addItems(computedItems, getMiddleOfficeActionItems());
    }

    if (authStore.hasRole(User.Role.OperatorSales)) {
      addItems(computedItems, getSalesActionItems());
    }

    if (authStore.hasRole(User.Role.OperatorFundSelection)) {
      addItems(computedItems, getFundSelectionActionItems());
    }

    if (authStore.hasRole(User.Role.Admin)) {
      addItems(computedItems, getAdminActionItems());
    }

    return computedItems;
  });

  const userItems = computed(() => {
    if (!authStore.authenticated) {
      return [];
    }

    return [
      { text: t('_app.nav.userItems.logout'), to: { name: 'logout' } },
    ];
  });

  const headTitle = ref(titleFromEntries(head.headEntries()));
  const breadcrumbsTailItem = computed<BreadcrumbsItem | undefined>(() => headTitle.value
    ? { title: headTitle.value, to: { name: undefined } }
    : undefined
  );

  head.hooks.hook('entries:resolve', ({ entries }) => {
    headTitle.value = titleFromEntries(entries);
  });

  const breadcrumbsItems = computed<Array<BreadcrumbsItem>>(() => {
    if (!authStore.authenticated) {
      return [];
    }

    const computedItems: Array<BreadcrumbsItem> = [{
      title: ''
    }];

    if (Array.isArray(route.meta.breadcrumbs)) {
      (route.meta.breadcrumbs as Array<BreadcrumbsItem>).forEach(item => computedItems.push({
        ...item,
        title: (item.title) ? t(item.title) : ''
      }));

      if (breadcrumbsTailItem.value) {
        computedItems.push(breadcrumbsTailItem.value);
      }
    }

    return computedItems;
  });

  return { actionItems, breadcrumbsItems, userItems, headTitle };
};
