<script lang="ts" setup>
withDefaults(defineProps<{
  color?: string;
  indeterminate?: boolean;
  verticalAlign?: 'top' | 'center';
  type?: 'circular' | 'linear';
}>(), {
  color: 'primary',
  indeterminate: true,
  verticalAlign: 'top',
  type: 'linear',
});
</script>

<template>
  <v-container
    :class="verticalAlign === 'center' ? ['h-100'] : []"
    fluid
  >
    <v-row
      class="fill-height"
      justify="center"
      align="center"
    >
      <v-col
        v-if="type === 'circular'"
        class="text-center"
      >
        <v-progress-circular
          :class="verticalAlign === 'top' ? ['my-4'] : []"
          :color="color"
          :indeterminate="indeterminate"
        />
      </v-col>
      <v-col
        v-else
        class="text-center"
      >
        <v-progress-linear
          :class="verticalAlign === 'top' ? ['my-8'] : []"
          :color="color"
          :indeterminate="indeterminate"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
