import { SignatureEvent } from '@opalecapital/api-client';

export const STATUS_COLOR: Record<SignatureEvent.Name, string> = {
  [SignatureEvent.Name.ApproverNotified]: 'info',
  [SignatureEvent.Name.AppoverApproved]: 'success',
  [SignatureEvent.Name.ApproverRejected]: 'warning',
  [SignatureEvent.Name.RequestActivated]: 'info',
  [SignatureEvent.Name.RequestCompleted]: 'info',
  [SignatureEvent.Name.SignatoryNotified]: 'info',
  [SignatureEvent.Name.SignatoryViewed]: 'info',
  [SignatureEvent.Name.SignatoryDone]: 'success',
};
