<script lang="ts" setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { LoadingContainer, SnackbarManager } from '~/components';
import { useDialogs } from '~/composables';
import { Http401Error } from '~/dialogs';
import { AppLayout } from '~/layouts';
import { initGuards } from './router/guards';
import {
  initEvents,
  initHead,
  initInterceptors,
  initLocale
} from './utils';

const dialogs = useDialogs({ Http401Error });
const { locale } = useI18n();

const initialized = ref(false);

// init
initGuards().then(() => initialized.value = true);
initEvents();
initInterceptors();
initHead();
initLocale();
</script>

<template>
  <v-app
    :key="`app.${locale}`"
    theme="opale-light"
  >
    <http401-error
      v-model="dialogs.http401Error.displayed.value"
      @close="dialogs.http401Error.hide()"
    />
    <snackbar-manager />

    <app-layout v-if="initialized" />
    <v-main v-else>
      <loading-container
        type="circular"
        vertical-align="center"
      />
    </v-main>
  </v-app>
</template>
