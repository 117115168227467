import { PartnerCompanyProfile } from '@opalecapital/api-client';

export const PROFILE_STATUS_COLOR: Record<PartnerCompanyProfile.Status, string> = {
  [PartnerCompanyProfile.Status.New]: 'warning',
  [PartnerCompanyProfile.Status.OnGoing]: 'warning',
  [PartnerCompanyProfile.Status.Signed]: 'info',
  [PartnerCompanyProfile.Status.SignatureInProgress]: 'info',
  [PartnerCompanyProfile.Status.Rejected]: 'error',
  [PartnerCompanyProfile.Status.Validated]: 'success'
};
