import { ref } from 'vue';

export const useDialogs = <T extends Record<string, unknown>>(dialogs: T) => {
  const buildDialogManager = () => {
    const displayed = ref(false);
    const show = () => displayed.value = true;
    const hide = () => displayed.value = false;

    return { displayed, show, hide };
  };

  const lcfirst = (key: string) => key.charAt(0).toLocaleLowerCase() + key.slice(1);

  type Name<S extends string> =  S extends `${infer T}Dialog` ? `${Uncapitalize<T>}` : Uncapitalize<S>;
  type DialogManagers = Record<Name<keyof T & string>, ReturnType<typeof buildDialogManager>>;

  return Object.fromEntries(
    Object.keys(dialogs).map(key => [lcfirst(key).replace('Dialog', ''), buildDialogManager()])
  ) as DialogManagers;
};
