import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const useLayout = defineStore(
  'layout',
  () => {
    const defaultSettings = {
      color: 'primary',
      indeterminate: true
    };

    const title = ref<string>();
    const loadingSettings = ref({ ...defaultSettings });
    const loading = ref(false);

    const activateLoading = (settings: Partial<typeof defaultSettings> = {}) => {
      loading.value = true;
      loadingSettings.value = { ...loadingSettings.value, ...settings };
    };

    const deactivateLoading = () => {
      loading.value = false;
      loadingSettings.value = { ...defaultSettings };
    };

    return {
      title,
      loading: computed(() => loading.value),
      settings: computed(() => loadingSettings.value),
      activateLoading,
      deactivateLoading
    };
  }
);
