<script lang="ts" setup>
import { useDisplay } from 'vuetify';
import opaleLogo from '~/assets/opale.png';
import type { BreadcrumbsItem, NavItem } from '~/composables';

withDefaults(defineProps<{
  userItems?: Array<NavItem>,
  breadcrumbsItems?: Array<BreadcrumbsItem>,
  displayHomeButton: boolean
}>(), {
  userItems: () => [],
  breadcrumbsItems: () => [],
  displayHomeButton: false
});

const display = useDisplay();
</script>

<template>
  <v-app-bar
    color="primary"
    class="main-app-bar"
  >
    <router-link
      class="logo"
      :to="{ name: 'dashboard'}"
      :style="{ width: '136px' }"
    >
      <v-img
        alt="Opale logo"
        :src="opaleLogo"
        class="mx-5"
      />
    </router-link>

    <v-breadcrumbs :items="breadcrumbsItems">
      <template
        v-if="displayHomeButton"
        #prepend
      >
        <router-link :to="{ name: 'dashboard'}">
          <v-icon
            size="small"
            icon="fa fa-house"
          />
        </router-link>
      </template>
    </v-breadcrumbs>

    <template v-if="!display.mobile.value">
      <v-spacer />

      <v-btn
        v-for="(userItem, index) in userItems"
        :key="`userActions.${index}`"
        class="mr-2"
        color="surface"
        variant="outlined"
        size="small"
        :to="userItem.to"
      >
        {{ userItem.text }}
      </v-btn>
    </template>
  </v-app-bar>
</template>

<style lang="scss">
  .main-app-bar {
    .v-breadcrumbs {
      &__prepend {
        &:hover {
          border-bottom: 1px solid rgb(var(--v-theme-on-primary));
        }

        .v-icon {
          margin-right: 0;
          vertical-align: baseline;
        }
      }
    }
  }
</style>
