<script lang="ts" setup>
import { useRouter } from 'vue-router';
import { useAuth } from '~/stores';

const authStore = useAuth();
const router = useRouter();

const emit = defineEmits(['close']);
const redirectToLoginPage = () => {
  authStore.logout();
  router.push({ name: 'login' });
  emit('close');
};
</script>

<template>
  <o-dialog
    :title="$t('_dialogs.http401Error.title')"
    :text="$t('_dialogs.http401Error.text')"
    type="warning"
    persistent
    :primary-btn-text="$t('_dialogs.http401Error.primaryBtnText')"
    @primary-btn-click="redirectToLoginPage"
  />
</template>
