<script lang="ts" setup>
import { kebabCase } from 'lodash';
import { useRoute } from 'vue-router';
import { AppHeader, AppNavigationDrawer, LoadingContainer, TitleContainer } from '~/components';
import { useNav } from '~/composables';
import { useAuth, useLayout } from '~/stores';

const route = useRoute();
const { actionItems, breadcrumbsItems, userItems, headTitle } = useNav();
const authStore = useAuth();
const layoutStore = useLayout();

defineOptions({ inheritAttrs: false });
</script>

<template>
  <app-header
    :user-items="userItems"
    :breadcrumbs-items="breadcrumbsItems"
    :display-home-button="authStore.authenticated"
  />
  <app-navigation-drawer
    v-if="authStore.authenticated"
    :action-items="actionItems"
  />
  <v-main :id="kebabCase(route.name?.toString())">
    <title-container
      v-if="route.meta.hasTitle"
      :title="headTitle"
    />
    <loading-container
      v-if="layoutStore.loading"
      :indeterminate="layoutStore.settings.indeterminate"
      :color="layoutStore.settings.color"
    />
    <router-view />
  </v-main>
</template>
