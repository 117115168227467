import type { ApiCredentials, User } from '@opalecapital/api-client';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const useAuth = defineStore(
  'auth',
  () => {
    const user = ref<Required<User>>();
    const authenticated = computed(() => user.value != undefined);
    const authenticatedUser = computed(() => user.value);

    const credentials = ref<ApiCredentials>();
    const rememberMe = ref(false);

    const hasRole = (userRole: User.Role) => user.value && user.value.roles.includes(userRole);

    const login = (
      newUser: Required<User>,
      newCredentials?: ApiCredentials,
      newRememberMe?: boolean
    ) => {
      if (newUser.roles.length == 0) {
        return logout();
      }

      user.value = newUser;

      if (newCredentials) {
        credentials.value = { ...newCredentials };
      }

      if (newRememberMe) {
        rememberMe.value = newRememberMe;
      }
    };

    const refresh = (newCredentials: ApiCredentials) => {
      credentials.value = { ...newCredentials };
    };

    const logout = () => {
      user.value = undefined;

      credentials.value = undefined;
      rememberMe.value = false;
    };

    return {
      credentials,
      rememberMe,
      authenticated,
      authenticatedUser,
      hasRole,
      login,
      refresh,
      logout
    };
  },
  {
    persist: true
  }
);
