import { StoredSignatureFile } from '@opalecapital/api-client';

export const STATUS_COLOR: Record<StoredSignatureFile.Status, string> = {
  [StoredSignatureFile.Status.ApproverRejected]: 'warning',
  [StoredSignatureFile.Status.Created]: 'info',
  [StoredSignatureFile.Status.Ready]: 'info',
  [StoredSignatureFile.Status.Sent]: 'info',
  [StoredSignatureFile.Status.Processing]: 'info',
  [StoredSignatureFile.Status.Cancelled]: 'info',
  [StoredSignatureFile.Status.ReminderSent]: 'info',
  [StoredSignatureFile.Status.ApproverApproved]: 'info',
  [StoredSignatureFile.Status.ApproverNotified]: 'info',
  [StoredSignatureFile.Status.InvestorSigned]: 'info',
  [StoredSignatureFile.Status.Signed]: 'success',
  [StoredSignatureFile.Status.SignedAndDownloaded]: 'success',
};
