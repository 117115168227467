<script lang="ts" setup>
import { useSnackbar } from '~/stores';

const snackbarStore = useSnackbar();
</script>

<template>
  <o-snackbar
    v-model="snackbarStore.display"
    :timeout="4000"
    :closable="snackbarStore.settings.closable"
    :type="snackbarStore.settings.type"
    :title="snackbarStore.settings.title"
    :text="snackbarStore.settings.text"
    :btn-primary-text="snackbarStore.settings.btnPrimaryText"
    :btn-secondary-text="snackbarStore.settings.btnSecondaryText"
    @close="snackbarStore.hide()"
  />
</template>
