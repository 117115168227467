import type { ApiError } from '@opalecapital/api-client';
import { useRouter } from 'vue-router';
import { Http401Error } from '../dialogs';
import { useDialogs } from './dialogs';

export const useErrors = () => {
  const dialogs = useDialogs({ Http401Error });
  const router = useRouter();

  const catchHttpError = (error: ApiError) => {
    switch (error.response?.status) {
      case 404:
        router.push({ name: '404' });
        break;
      case 401:
        dialogs.http401Error.show();
        break;
      default:
        console.error(error);
    }
  };

  return { catchHttpError };
};
