import { useHead } from '@vueuse/head';
import { camelCase } from 'lodash';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import p from '../../package.json';

export const initHead = () => {
  const { t, te } = useI18n();
  const route = useRoute();

  useHead({
    title: computed(() => {
      if (typeof route.name !== 'string') {
        return null;
      }

      const titleKey = `${camelCase(route.name)}._meta.title`;

      return (te(titleKey)) ? t(titleKey) : null ;
    }),
    titleTemplate: (title?: string) => title ? `${title} - Opale Capital` : 'Opale Capital',
    meta: [
      {
        name: 'version',
        content: p.version
      }
    ]
  });
};
